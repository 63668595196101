// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bulb {
    width: 16px;
    height: 16px;
    fill: black;
    stroke: white;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

  .bulb.green {
    fill: green;
  }

  .bulb.yellow {
    fill: yellow;
  }

  .bulb.purple {
    fill: purple;
  }

  .bulb.red {
    fill: red;
  }

  .bulb.gray {
    fill: gray;
  }

  .lightbulb {
    background-image: url('#src/images/lightbulb-base.svg');
    background-size: contain;
    background-repeat: no-repeat;
    width: 16px;
    height: 16px;
  }
  
  .lightbulb.complete {
    background-image: url('#src/images/lightbulb-green.svg');
  }
  
  .lightbulb.error {
    background-image: url('#src/images/lightbulb-base.svg');
  }
  
  .lightbulb.outdated {
    background-image: url('#src/images/lightbulb-yellow.svg');
  }
  
  .lightbulb.unlabeled {
    background-image: url('#src/images/lightbulb-purple.svg');
  }
  
  .lightbulb.hidden {
    display: none;
  }
  `, "",{"version":3,"sources":["webpack://./src/widgets/bulb.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,WAAW;IACX,aAAa;IACb,eAAe;IACf,qBAAqB;IACrB,sBAAsB;EACxB;;EAEA;IACE,WAAW;EACb;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,SAAS;EACX;;EAEA;IACE,UAAU;EACZ;;EAEA;IACE,uDAAuD;IACvD,wBAAwB;IACxB,4BAA4B;IAC5B,WAAW;IACX,YAAY;EACd;;EAEA;IACE,wDAAwD;EAC1D;;EAEA;IACE,uDAAuD;EACzD;;EAEA;IACE,yDAAyD;EAC3D;;EAEA;IACE,yDAAyD;EAC3D;;EAEA;IACE,aAAa;EACf","sourcesContent":[".bulb {\n    width: 16px;\n    height: 16px;\n    fill: black;\n    stroke: white;\n    stroke-width: 2;\n    stroke-linecap: round;\n    stroke-linejoin: round;\n  }\n\n  .bulb.green {\n    fill: green;\n  }\n\n  .bulb.yellow {\n    fill: yellow;\n  }\n\n  .bulb.purple {\n    fill: purple;\n  }\n\n  .bulb.red {\n    fill: red;\n  }\n\n  .bulb.gray {\n    fill: gray;\n  }\n\n  .lightbulb {\n    background-image: url('#src/images/lightbulb-base.svg');\n    background-size: contain;\n    background-repeat: no-repeat;\n    width: 16px;\n    height: 16px;\n  }\n  \n  .lightbulb.complete {\n    background-image: url('#src/images/lightbulb-green.svg');\n  }\n  \n  .lightbulb.error {\n    background-image: url('#src/images/lightbulb-base.svg');\n  }\n  \n  .lightbulb.outdated {\n    background-image: url('#src/images/lightbulb-yellow.svg');\n  }\n  \n  .lightbulb.unlabeled {\n    background-image: url('#src/images/lightbulb-purple.svg');\n  }\n  \n  .lightbulb.hidden {\n    display: none;\n  }\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
