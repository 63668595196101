import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2df46602"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "dropdownMenu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_visible = _resolveDirective("visible")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["dropdownList", { open: $setup.isActive }]),
      id: $props.id
    }, [
      _createElementVNode("button", { onClick: $setup.toggleVisible }, [
        _renderSlot(_ctx.$slots, "buttonTitle", {}, undefined, true)
      ]),
      _withDirectives((_openBlock(), _createElementBlock("ul", _hoisted_2, [
        _renderSlot(_ctx.$slots, "listItems", {}, undefined, true)
      ])), [
        [_directive_visible, $setup.isActive]
      ])
    ], 10 /* CLASS, PROPS */, _hoisted_1),
    _createCommentVNode(" <div class=\"dropdownList\" :class=\"{ open: isActive }\" :style=\"{width: width, 'min-width': width}\" @mousedown.stop.prevent>\n    <template v-if=\"type === 'chooser'\">\n      <div class=\"dropdownChooser\" @click=\"toggleVisible\" :title=\"hover\">\n        <div class=\"dropdownChooserLabel\">\n          <div class=\"dropdownChooserTitle\"><slot name=\"chooserTitle\"></slot></div>\n          <div class=\"dropdownChooserArrow\"></div>\n        </div>\n      </div>\n      <ul v-visible=\"isActive\" class=\"dropdownMenu chooser\">\n        <div class=\"dropdownChooserLabel\" @click=\"toggleVisible\">\n          <div class=\"dropdownChooserTitle\"><slot name=\"chooserTitle\"></slot></div>\n          <div class=\"dropdownChooserArrow\"></div>\n        </div>\n        <slot name=\"listItems\"></slot>\n      </ul>\n    </template>\n<template v-else>\n      <button @click=\"toggleVisible\" :title=\"hover\"><slot name=\"buttonTitle\"></slot></button>\n      <ul v-visible=\"isActive\" class=\"dropdownMenu\">\n        <slot name=\"listItems\"></slot>\n      </ul>\n      <div class=\"dropdownArrow\" v-visible=\"isActive\"></div>\n    </template>\n</div> ")
  ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
}