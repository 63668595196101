// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.nge-overlay {
  pointer-events: initial;
}
`, "",{"version":3,"sources":["webpack://./src/components/Overlay.vue"],"names":[],"mappings":";AAUA;EACE,uBAAuB;AACzB","sourcesContent":["<script setup lang=\"ts\">\n</script>\n\n<template>\n  <div class=\"nge-overlay\" @click.stop @mousedown.stop>\n    <slot></slot>\n  </div>\n</template>\n\n<style>\n.nge-overlay {\n  pointer-events: initial;\n}\n</style>"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
