// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .neuroglancer-layer-side-panel-title {
    display: none;
}

.neuroglancer-layer-side-panel {
    margin-top: 80px;
}

.neuroglancer-minimizable-group-title {
    background-color: var(--color-medium-bg);
    color: var(--color-small-text);
    padding: 8px;
    font-weight: bold;
} */

.neuroglancer-viewer-top-row {
    background-color: var(--color-dark-bg);
    margin-bottom: unset;
}

.ng-extend .overlay-content, .overlay-content.ng-dark {
  /* background-color: var(--color-dark-bg); */
  color: var(--color-small-text);
  border-radius: 10px;
  outline: none;
  min-width: max-content;

  background-color: hsl(0 0% 10% / 1);
  border: 1px solid hsl(0 0% 18% / 1);
}

/*

input.rounded-input {
    outline: none;
}

div.segment-div > * {
    margin: 1px;
}

div.segment-div > .segment-checkbox {
    margin-bottom: 3px;
}

div.segment-div > .segment-color-selector {
    border: none;
    border-color: transparent;
    -webkit-appearance: none;
    background-color: transparent;
    padding: 0;
    margin: 0px;
    height: 23px;
}


.neuroglancer-minimizable-group {
    flex: unset;
}

.nge-segment-management-field-coord {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
  color: #aaa;
  pointer-events: none;
} */
`, "",{"version":3,"sources":["webpack://./src/ng-override.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;;;GAaG;;AAEH;IACI,sCAAsC;IACtC,oBAAoB;AACxB;;AAEA;EACE,4CAA4C;EAC5C,8BAA8B;EAC9B,mBAAmB;EACnB,aAAa;EACb,sBAAsB;;EAEtB,mCAAmC;EACnC,mCAAmC;AACrC;;AAEA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;GAmCG","sourcesContent":["/* .neuroglancer-layer-side-panel-title {\n    display: none;\n}\n\n.neuroglancer-layer-side-panel {\n    margin-top: 80px;\n}\n\n.neuroglancer-minimizable-group-title {\n    background-color: var(--color-medium-bg);\n    color: var(--color-small-text);\n    padding: 8px;\n    font-weight: bold;\n} */\n\n.neuroglancer-viewer-top-row {\n    background-color: var(--color-dark-bg);\n    margin-bottom: unset;\n}\n\n.ng-extend .overlay-content, .overlay-content.ng-dark {\n  /* background-color: var(--color-dark-bg); */\n  color: var(--color-small-text);\n  border-radius: 10px;\n  outline: none;\n  min-width: max-content;\n\n  background-color: hsl(0 0% 10% / 1);\n  border: 1px solid hsl(0 0% 18% / 1);\n}\n\n/*\n\ninput.rounded-input {\n    outline: none;\n}\n\ndiv.segment-div > * {\n    margin: 1px;\n}\n\ndiv.segment-div > .segment-checkbox {\n    margin-bottom: 3px;\n}\n\ndiv.segment-div > .segment-color-selector {\n    border: none;\n    border-color: transparent;\n    -webkit-appearance: none;\n    background-color: transparent;\n    padding: 0;\n    margin: 0px;\n    height: 23px;\n}\n\n\n.neuroglancer-minimizable-group {\n    flex: unset;\n}\n\n.nge-segment-management-field-coord {\n  background-color: transparent;\n  border-color: transparent;\n  box-shadow: none;\n  color: #aaa;\n  pointer-events: none;\n} */\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
