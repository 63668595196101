// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js!../common.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../node_modules/css-loader/dist/cjs.js!../ng-override.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
#vueMain {
  position: relative;
  flex-direction: column;
}
#vueMain>*:not(#content) {
  font-family: 'Roboto', sans-serif;
}
#vueMain>*:not(#content) ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
#app,
#content,
#vueMain {
  display: flex;
  flex: 1;
}
`, "",{"version":3,"sources":["webpack://./src/components/App.vue"],"names":[],"mappings":";AAmBA;EACE,kBAAkB;EAClB,sBAAsB;AACxB;AAEA;EACE,iCAAiC;AACnC;AAEA;EACE,gBAAgB;EAChB,SAAS;EACT,UAAU;AACZ;AAEA;;;EAGE,aAAa;EACb,OAAO;AACT","sourcesContent":["<script setup lang=\"ts\">\nimport ExtensionBar from \"#src/components/ExtensionBar.vue\";\n</script>\n\n<template>\n  <div id=\"vueMain\">\n    <div class=\"ng-extend\">\n      <ExtensionBar />\n    </div>\n    <div id=\"content\">\n      <div id=\"neuroglancer-container\"></div>\n    </div>\n  </div>\n</template>\n\n<style>\n@import \"../common.css\";\n@import \"../ng-override.css\";\n\n#vueMain {\n  position: relative;\n  flex-direction: column;\n}\n\n#vueMain>*:not(#content) {\n  font-family: 'Roboto', sans-serif;\n}\n\n#vueMain>*:not(#content) ul {\n  list-style: none;\n  margin: 0;\n  padding: 0;\n}\n\n#app,\n#content,\n#vueMain {\n  display: flex;\n  flex: 1;\n}\n</style>"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
