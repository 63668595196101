// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.neuroglancer-layer-group-viewer-context-menu {
    border-style: solid;
    border-color: white;
    border-width: 1px;
    border-radius: 5px;
    -webkit-border-radius: 10px;
    font-size: 17px;
    font-family: Roboto;
    color: white;
    padding: 5px;
    padding-left: 20px;
    padding-right: 40px;
    margin: 30px 0px;
}

.neuroglancer-layer-group-viewer-context-menu-title-element {
    font-size: 25px;
    font-weight: bold;
    margin: 12px 0px;
    text-transform: uppercase;
}

.neuroglancer-layer-group-viewer-context-menu-body-element {
    font-size: 16px;
    font-weight: normal;
    margin: 12px 0px;
}

.neuroglancer-layer-group-viewer-context-menu-button-element {
    border-radius: 5px;
    -webkit-border-radius: 10px;
    color: white;
    font-size: 13px;
    font-family: Roboto;
    text-transform: uppercase;
    cursor: pointer;
    padding: 5px 20px;
}`, "",{"version":3,"sources":["webpack://./src/widgets/menu.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,mBAAmB;IACnB,iBAAiB;IACjB,kBAAkB;IAClB,2BAA2B;IAC3B,eAAe;IACf,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,gBAAgB;IAChB,yBAAyB;AAC7B;;AAEA;IACI,eAAe;IACf,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,2BAA2B;IAC3B,YAAY;IACZ,eAAe;IACf,mBAAmB;IACnB,yBAAyB;IACzB,eAAe;IACf,iBAAiB;AACrB","sourcesContent":[".neuroglancer-layer-group-viewer-context-menu {\n    border-style: solid;\n    border-color: white;\n    border-width: 1px;\n    border-radius: 5px;\n    -webkit-border-radius: 10px;\n    font-size: 17px;\n    font-family: Roboto;\n    color: white;\n    padding: 5px;\n    padding-left: 20px;\n    padding-right: 40px;\n    margin: 30px 0px;\n}\n\n.neuroglancer-layer-group-viewer-context-menu-title-element {\n    font-size: 25px;\n    font-weight: bold;\n    margin: 12px 0px;\n    text-transform: uppercase;\n}\n\n.neuroglancer-layer-group-viewer-context-menu-body-element {\n    font-size: 16px;\n    font-weight: normal;\n    margin: 12px 0px;\n}\n\n.neuroglancer-layer-group-viewer-context-menu-button-element {\n    border-radius: 5px;\n    -webkit-border-radius: 10px;\n    color: white;\n    font-size: 13px;\n    font-family: Roboto;\n    text-transform: uppercase;\n    cursor: pointer;\n    padding: 5px 20px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
