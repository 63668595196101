// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --color-dark-bg: hsl(230 0% 14% / 1);
  --color-medium-bg: #33353c;
  --color-light-bg: #4d505b;
  --color-small-text: #eee;
  --color-border: #a3b3bf;
  --gradient-highlight: linear-gradient(-45deg, rgba(2, 146, 174, 0.88), rgba(15, 177, 139, 0.88));
  --gradient-highlight-hover: linear-gradient(-45deg, rgba(2, 146, 174, 1), rgba(15, 177, 139, 1));
  --color-flywire-dark-green: #0FB18B;
  --color-ng-selected: #8c8;
  --color-ng-selected-faint: #88cc8838;
  --color-ng-selected-less-faint: #88cc8852;
}

.ng-extend button {
  display: inline-block;
  border: none;
  padding: 0;
  margin: 0;
  text-decoration: none;
  background: none;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  cursor: pointer;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  white-space: nowrap;
}

.ng-extend button:hover {
  background-color: var(--color-light-bg);
}

.ng-extend button:disabled {
  opacity: 0.5 !important;
}

.ng-extend button.fill, .ng-extend button.fill:hover {
  opacity: 1;
  background-color: #0FB18B;
  /* border: 1px solid #0c8468; */
  padding: 5px;
  border-radius: 5px;
}

.ng-extend .flex-fill {
  flex-grow: 1;
}`, "",{"version":3,"sources":["webpack://./src/common.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,0BAA0B;EAC1B,yBAAyB;EACzB,wBAAwB;EACxB,uBAAuB;EACvB,gGAAgG;EAChG,gGAAgG;EAChG,mCAAmC;EACnC,yBAAyB;EACzB,oCAAoC;EACpC,yCAAyC;AAC3C;;AAEA;EACE,qBAAqB;EACrB,YAAY;EACZ,UAAU;EACV,SAAS;EACT,qBAAqB;EACrB,gBAAgB;EAChB,cAAc;EACd,oBAAoB;EACpB,kBAAkB;EAClB,oBAAoB;EACpB,eAAe;EACf,kBAAkB;EAClB,wBAAwB;EACxB,qBAAqB;EACrB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,uCAAuC;AACzC;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,UAAU;EACV,yBAAyB;EACzB,+BAA+B;EAC/B,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd","sourcesContent":[":root {\n  --color-dark-bg: hsl(230 0% 14% / 1);\n  --color-medium-bg: #33353c;\n  --color-light-bg: #4d505b;\n  --color-small-text: #eee;\n  --color-border: #a3b3bf;\n  --gradient-highlight: linear-gradient(-45deg, rgba(2, 146, 174, 0.88), rgba(15, 177, 139, 0.88));\n  --gradient-highlight-hover: linear-gradient(-45deg, rgba(2, 146, 174, 1), rgba(15, 177, 139, 1));\n  --color-flywire-dark-green: #0FB18B;\n  --color-ng-selected: #8c8;\n  --color-ng-selected-faint: #88cc8838;\n  --color-ng-selected-less-faint: #88cc8852;\n}\n\n.ng-extend button {\n  display: inline-block;\n  border: none;\n  padding: 0;\n  margin: 0;\n  text-decoration: none;\n  background: none;\n  color: inherit;\n  font-family: inherit;\n  font-size: inherit;\n  font-weight: inherit;\n  cursor: pointer;\n  text-align: center;\n  -webkit-appearance: none;\n  -moz-appearance: none;\n  outline: none;\n  white-space: nowrap;\n}\n\n.ng-extend button:hover {\n  background-color: var(--color-light-bg);\n}\n\n.ng-extend button:disabled {\n  opacity: 0.5 !important;\n}\n\n.ng-extend button.fill, .ng-extend button.fill:hover {\n  opacity: 1;\n  background-color: #0FB18B;\n  /* border: 1px solid #0c8468; */\n  padding: 5px;\n  border-radius: 5px;\n}\n\n.ng-extend .flex-fill {\n  flex-grow: 1;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
