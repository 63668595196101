// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.nge-overlay-blocker {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  pointer-events: initial;
}
.nge-overlay-content {
  min-width: 250px;
}
.nge-overlay-content .title {
  background-color: lightgray;
  font-size: 1.25em;
}
.nge-overlay.modal.overlay-content {
  overflow: visible;
}
`, "",{"version":3,"sources":["webpack://./src/components/ModalOverlay.vue"],"names":[],"mappings":";AAmBA;EACE,YAAY;EACZ,WAAW;EACX,eAAe;EACf,WAAW;EACX,MAAM;EACN,OAAO;EACP,oCAAoC;EACpC,uBAAuB;AACzB;AAEA;EACE,gBAAgB;AAClB;AAEA;EACE,2BAA2B;EAC3B,iBAAiB;AACnB;AAEA;EACE,iBAAiB;AACnB","sourcesContent":["<script setup lang=\"ts\">\n// import Vue from \"vue\";\n\nimport Overlay from \"#src/components/Overlay.vue\";\n\n// export default Vue.extend({\n//   components: { Overlay }\n// });\n</script>\n\n<template>\n  <div class=\"nge-overlay-blocker\" @click.stop=\"$emit('hide')\" @mousedown.stop>\n    <overlay class=\"modal overlay-content\">\n      <slot></slot>\n    </overlay>\n  </div>\n</template>\n\n<style>\n.nge-overlay-blocker {\n  height: 100%;\n  width: 100%;\n  position: fixed;\n  z-index: 99;\n  top: 0;\n  left: 0;\n  background-color: rgba(0, 0, 0, 0.8);\n  pointer-events: initial;\n}\n\n.nge-overlay-content {\n  min-width: 250px;\n}\n\n.nge-overlay-content .title {\n  background-color: lightgray;\n  font-size: 1.25em;\n}\n\n.nge-overlay.modal.overlay-content {\n  overflow: visible;\n}\n</style>"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
